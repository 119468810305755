import React, { useMemo } from 'react'
import { remark } from 'remark'
import strip from 'strip-markdown'

import { useMeta, useTitle, useScript } from 'hoofd'

// import { HOST_NAME } from '@data/config/url'
import { DEFAULT_BACKGROUND } from '@data/config/images'

// import guildIconPng from './Logo/images/guild-icon.png'

const IMAGEKIT_PARAMS = {
  DEFAULT: {
    'og:image': '?tr=w-1200',
    // 'twitter:image': '?tr=w-1200,ar-2-1,fo-auto',
    'twitter:image': '?tr=w-1200,h-600',
  },
  FULL_SIZE: {
    'og:image': '?tr=w-1200',
    // 'twitter:image': '?tr=w-1200,h-600,cm-pad_resize,bg-000000',
    'twitter:image': '?tr=w-1200,h-600',
  },
  NONE: {
    'og:image': '',
    'twitter:image': '',
  },
} as const

// const WEBSITE_STRUCTURED_DATA = JSON.stringify({
//   '@context': 'https://schema.org',
//   '@type': 'WebSite',
//   name: 'Guild',
//   url: 'https://guild.host/',
// })

// const ORGANIZATION_STRUCTURED_DATA = JSON.stringify({
//   '@context': 'https://schema.org',
//   '@type': 'Organization',
//   name: 'Guild',
//   description:
//     'Guild is the platform for communities. Attend an Event, catch a Presentation, and follow a Network!',
//   url: 'https://guild.host/',
//   logo: `${HOST_NAME}/${guildIconPng}`,
//   image: DEFAULT_BACKGROUND,
//   email: 'hi@guild.host',
// })

export const Metadata = ({
  description,
  imageKitParams = 'DEFAULT',
  photoUrl,
  title,
}: {
  description?: string | null
  imageKitParams?: keyof typeof IMAGEKIT_PARAMS
  photoUrl?: string | null
  title: string
}) => {
  const imageURL = useMemo(() => {
    return photoUrl ?? DEFAULT_BACKGROUND
  }, [photoUrl])

  const contentDescription = useMemo(() => {
    if (!description) {
      return
    }

    return String(remark().use(strip).processSync(description))
  }, [description])

  useTitle(`${title} | Guild`)
  useMeta({ property: 'og:title', content: `${title} | Guild` })

  // useMeta({ property: 'og:site_name', content: 'Guild' })

  // useScript({
  //   type: 'application/ld+json',
  //   text: WEBSITE_STRUCTURED_DATA,
  // })

  // useScript({
  //   type: 'application/ld+json',
  //   text: ORGANIZATION_STRUCTURED_DATA,
  // })

  useMeta({
    property: 'og:image',
    content: `${imageURL}${IMAGEKIT_PARAMS[imageKitParams]['og:image']}`,
  })

  useMeta({ property: 'twitter:title', content: title })
  useMeta({ property: 'twitter:card', content: 'summary_large_image' })
  useMeta({
    property: 'twitter:image',
    content: `${imageURL}${IMAGEKIT_PARAMS[imageKitParams]['twitter:image']}`,
  })

  return useMemo(
    () =>
      contentDescription ? (
        <MetadataDescription description={contentDescription} />
      ) : null,
    [contentDescription]
  )
}

const MetadataDescription = ({ description }: { description: string }) => {
  const shortenedDescription = description.split('\n').slice(0, 4).join('\n')

  useMeta({
    property: 'description',
    content: shortenedDescription,
  })
  useMeta({
    property: 'og:description',
    content: description.substring(0, 140),
  })
  useMeta({ property: 'twitter:description', content: shortenedDescription })

  return null
}
