import React, {
  createContext,
  useRef,
  useMemo,
  useCallback,
  useContext,
  useState,
} from 'react'

import type { LayoutRectangle } from 'react-native'

import { withStaticProperties, View, ViewProps } from 'tamagui'

const GroupContext = createContext({
  activeIndex: 0,
  setIndex: (newIndex: number) => undefined as void,
  setIndexDimensions: (index: number, dimensions: LayoutRectangle) =>
    undefined as void,
})

const Container = View.styleable<{
  activeIndex: number
  highlightedElementProps?: ViewProps
}>(({ activeIndex, children, highlightedElementProps = {}, ...props }, ref) => {
  const isPointerOver = useRef(false)

  const [itemDimensions, setItemDimensions] = useState<
    Record<number, LayoutRectangle>
  >({})

  const [displayedIndex, setDisplayedIndex] = useState(activeIndex)

  const contextValue = useMemo(
    () => ({
      activeIndex,
      setIndex: (newIndex: number) => {
        setDisplayedIndex(newIndex)
      },
      setIndexDimensions: (index: number, dimensions: LayoutRectangle) => {
        setItemDimensions((oldDimensions) => ({
          ...oldDimensions,
          [index]: dimensions,
        }))
      },
    }),
    [activeIndex, setDisplayedIndex, setItemDimensions]
  )

  const handlePointerEnter = useCallback(() => {
    isPointerOver.current = true
  }, [isPointerOver])

  const handlePointerLeave = useCallback(() => {
    isPointerOver.current = false

    setDisplayedIndex(activeIndex)
  }, [isPointerOver, activeIndex, setDisplayedIndex])

  const displayedItemDimensions =
    itemDimensions[isPointerOver.current ? displayedIndex : activeIndex]

  return (
    <View
      {...props}
      ref={ref}
      cursor='pointer'
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {displayedItemDimensions &&
        displayedItemDimensions.height &&
        displayedItemDimensions.width && (
          <View
            borderRadius='$3'
            backgroundColor='$lightBlue2'
            {...highlightedElementProps}
            animation='fastSpring'
            position='absolute'
            height={displayedItemDimensions.height}
            width={displayedItemDimensions.width}
            left={displayedItemDimensions.x}
            top={displayedItemDimensions.y}
          />
        )}

      <GroupContext.Provider value={contextValue}>
        {children}
      </GroupContext.Provider>
    </View>
  )
})

const Item = View.styleable<{
  children: React.ReactNode
  index: number
}>(({ children, index, ...props }, ref) => {
  const group = useContext(GroupContext)

  return (
    <View
      {...props}
      ref={ref}
      onLayout={(event) => {
        group.setIndexDimensions(index, event.nativeEvent.layout)
      }}
      onHoverIn={() => {
        group.setIndex(index)
      }}
    >
      {children}
    </View>
  )
})

export const Group = withStaticProperties(Container, { Item })
