import React from 'react'

import { YStack, styled } from 'tamagui'

import BackgroundGradientPath from './BackgroundGradient.svg'

import { HERO_BACKGROUND_IMAGE_URL } from '../data/config/images'

export const Screen = styled(YStack, {
  name: 'Screen',

  marginTop: 0,
  marginLeft: 'auto',
  marginRight: 'auto',

  paddingTop: 24 * 4,
  // paddingHorizontal: 5 * 4,
  paddingBottom: 5 * 4,

  width: '100%',
  maxWidth: '100%',
  flexGrow: 1,

  $gtXs: {
    paddingBottom: 20 * 4,
    paddingHorizontal: 8 * 4,
  },

  $gtSm: {
    style: {
      paddingLeft: `calc(env(safe-area-inset-left, 10px) + 32px)`,
      paddingRight: `calc(env(safe-area-inset-left, 10px) + 32px)`,
    },
  },

  $gtLg: {
    maxWidth: 1200,
  },

  style: {
    paddingLeft: `calc(env(safe-area-inset-left, 10px) + 20px)`,
    paddingRight: `calc(env(safe-area-inset-left, 10px) + 20px)`,
  },
} as const)

export const GradientBackgroundScreen = styled(YStack, {
  name: 'Screen',

  marginTop: 0,
  marginLeft: 'auto',
  marginRight: 'auto',

  paddingTop: 24 * 4,
  // paddingHorizontal: 5 * 4,
  paddingBottom: 5 * 4,

  width: '100%',
  maxWidth: '100%',
  flexGrow: 1,

  $gtXs: {
    paddingBottom: 20 * 4,
    paddingHorizontal: 8 * 4,
  },

  $gtSm: {
    style: {
      paddingLeft: `calc(env(safe-area-inset-left, 10px) + 32px)`,
      paddingRight: `calc(env(safe-area-inset-left, 10px) + 32px)`,
    },
  },

  style: {
    paddingLeft: `calc(env(safe-area-inset-left, 10px) + 20px)`,
    paddingRight: `calc(env(safe-area-inset-left, 10px) + 20px)`,

    backgroundImage: `url('${BackgroundGradientPath}')`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
  },
} as const)
