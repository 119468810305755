import React from 'react'
import { XStack, YStack, styled, withStaticProperties } from 'tamagui'

export const Grid = withStaticProperties(
  styled(XStack, {
    name: 'Grid',

    flex: 1,
    flexDirection: 'row',

    style: {
      flexBasis: '0%',
    },
  }),
  {
    Column: styled(YStack, {
      name: 'GridColumn',

      flex: 1,
      flexDirection: 'column',
      flexBasis: 0,

      style: {
        flexBasis: '0%',
      },
    }),
  }
)
