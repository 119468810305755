import 'core-js/actual/object/has-own'
import React, { ReactNode, useMemo, useState, useCallback } from 'react'
import Markdown, { Options, ExtraProps } from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import { visit } from 'unist-util-visit'

import {
  YStack,
  styled,
  View,
  Token,
  getConfig,
  getFontSize,
  FontSizeTokens,
  StackProps,
} from 'tamagui'
import { ExternalLink, H1, H2, H3, Strong, Text, Paragraph } from '../index'

import './FormattedMarkdown.css'

const FormattedMarkdownVariantEnum = {
  FULL_RICH_TEXT: 'FULL_RICH_TEXT',
  PLAIN_TEXT: 'PLAIN_TEXT',
} as const

type FormattedMarkdownProps = {
  formattedVariant?: keyof typeof FormattedMarkdownVariantEnum
  textSize?: FontSizeTokens
  numberOfLines?: number
  expandMarkdownChildren?: React.ReactNode
} & Options

const REMARK_PLUGINS = [
  // Formats plain urls (www.guild.host, https://www.guild.host)
  remarkGfm,
]

// const REHYPE_PLUGINS = [
//   function rehypePluginAddingIndex() {
//     return (tree) => {
//       visit(tree, (node, index) => {
//         if (node.type === 'element' && typeof index === 'number') {
//           if (!node.properties) {
//             node.properties = {}
//           }

//           node.properties.index = index
//         }
//       })
//     }
//   },
// ]

// should match `components` returned from useMarkDownProps
const allowedElements = [
  'a',
  'em',
  'h1',
  'h2',
  'h3',
  'li',
  'ol',
  'p',
  'strong',
  'ul',
]

function renderHeading(props: {
  node: { tagName: 'h1' | 'h2' | 'h3' | unknown }
}) {
  switch (props.node.tagName) {
    case 'h1': // intentional pass-through
    // return <H1 children={props.children} />
    case 'h2':
      return <H2 children={props.children} />
    default:
      return <H3 children={props.children} />
  }
}

function renderOrderedList(props) {
  return <OrderedList gap='$1'>{props.children}</OrderedList>
}

function renderUnorderedList(props) {
  return <UnorderedList gap='$1'>{props.children}</UnorderedList>
}

/**
 * Map html elements to Guild react-native components.
 */
function useMarkDownProps({
  formattedVariant,
  textSize = '$5',
  ...markdownProps
}: FormattedMarkdownProps): Options {
  return useMemo(() => {
    function a({ children, href }: { children: ReactNode; href?: string }) {
      if (!href) {
        return (
          <Text fontSize='inherit' lineHeight='inherit' fontWeight='inherit'>
            {children}
          </Text>
        )
      }

      return (
        <ExternalLink
          href={href}
          fontSize='inherit'
          lineHeight='inherit'
          fontWeight='inherit'
        >
          {children}
        </ExternalLink>
      )
    }

    function li({ children }: { children: React.ReactNode }) {
      const listItemChildren = useMemo(() => {
        if (!children || !Array.isArray(children)) {
          return children
        }

        const arrayChildren = children.filter(
          (child: string | React.ReactNode) => {
            if (typeof child === 'string') {
              return !!child
            }

            return true
          }
        )

        if (arrayChildren.length === 1) {
          return arrayChildren[0]
        }

        return arrayChildren
      }, [children])

      const liStyle = useMemo(() => {
        return {
          fontSize: getFontSize(textSize),
          lineHeight: getFontSize(textSize) * ((1 + Math.pow(5, 0.25)) / 2),
        }
      }, [textSize])

      return (
        <Li style={liStyle}>
          <Paragraph size={textSize}>{listItemChildren}</Paragraph>
        </Li>
      )
    }

    function p({ children }: { children: React.ReactNode }) {
      if (Array.isArray(children)) {
        if (
          children.length === 1 &&
          typeof children[0] === 'string' &&
          children[0].endsWith('\n')
        ) {
          return <Paragraph size={textSize}>{children[0]}</Paragraph>
        }

        return (
          <Paragraph size={textSize}>
            {children.map((child, index) => {
              if (typeof child === 'string') {
                if (child.includes('\n')) {
                  return child
                    .split('\n')
                    .map((stringSplit, splitIndex) => {
                      if (!stringSplit) {
                        return null
                      }

                      return (
                        <Text size={textSize} key={splitIndex}>
                          {stringSplit}
                        </Text>
                      )
                    })
                    .reduce((memo, item, index, initialArray) => {
                      if (index === initialArray.length - 1) {
                        if (item === null) {
                          return memo
                        }
                        return [...memo, item]
                      }

                      if (item === null) {
                        return [
                          ...memo,
                          <br key={initialArray.length + index} />,
                        ]
                      }

                      return [
                        ...memo,
                        item,
                        <br key={initialArray.length + index} />,
                      ]
                    }, [] as Array<React.JSX.Element>)
                }

                return (
                  <Text fontSize='inherit' lineHeight='inherit'>
                    {child}
                  </Text>
                )
              }

              return child
            })}
          </Paragraph>
        )
      }

      if (typeof children === 'string') {
        const textParts = children.split('\n').map((child) => `${child}\n`)

        return p({ children: textParts })
      }

      return <Paragraph size={textSize}>{children}</Paragraph>
    }

    function em({ children }: { children: React.ReactNode }) {
      return (
        <Text fontStyle='italic' size={textSize}>
          {children}
        </Text>
      )
    }

    function strong({ children }: { children: React.ReactNode }) {
      return (
        <Strong fontSize='inherit' lineHeight='inherit'>
          {children}
        </Strong>
      )
    }

    switch (formattedVariant) {
      case FormattedMarkdownVariantEnum.PLAIN_TEXT:
        return {
          ...markdownProps,
          children: markdownProps.children.trim(),
          components: {
            a,
            h1: p,
            h2: p,
            h3: p,
            li,
            p,
            em,
            ol: renderOrderedList,
            ul: renderUnorderedList,
          },
          allowedElements: ['a', 'h1', 'h2', 'h3', 'li', 'ol', 'p', 'ul'],
        }
      case FormattedMarkdownVariantEnum.FULL_RICH_TEXT:
      default:
        return {
          ...markdownProps,
          allowedElements,
          children: markdownProps.children.trim(),
          // our custom `components` should always be enforced,
          // because we have to translate the web html elements to react-native.
          // should match allowedElements
          components: {
            a,
            h1: renderHeading,
            h2: renderHeading,
            h3: renderHeading,
            li,
            ol: renderOrderedList,
            ul: renderUnorderedList,
            p,
            em,
            strong,
          },
        }
    }
  }, [formattedVariant, textSize, markdownProps])
}

const LimitNumberOfLines = ({
  numberOfLines,
  textSize,
  children,
  expandMarkdownChildren,
}: {
  numberOfLines: number
  textSize: FontSizeTokens
  children: React.ReactNode
  expandMarkdownChildren?: React.ReactNode
}) => {
  const lineHeight = useMemo(() => {
    return getFontSize(textSize) * ((1 + Math.pow(5, 0.25)) / 2)
  }, [textSize])

  const [dimensions, setDimensions] = useState<{ height: number }>({
    height: 0,
  })

  const onLayout = useCallback(
    ({ nativeEvent: { layout } }) => {
      setDimensions(layout)
    },
    [setDimensions]
  )

  if (!lineHeight) {
    console.warn(
      'Could not find lineHeight to limit Markdown Number Of Lines',
      textSize
    )

    return <>{children}</>
  }

  const maxHeight = lineHeight * numberOfLines

  return (
    <>
      <StyledLimitNumberOfLines
        style={{
          maxHeight,
        }}
        className={
          Math.abs(maxHeight - dimensions.height) <= 1
            ? 'LimitNumberOfLinesGradient'
            : undefined
        }
        onLayout={onLayout}
      >
        {children}
      </StyledLimitNumberOfLines>

      {expandMarkdownChildren}
    </>
  )
}

export const FormattedMarkdown = YStack.styleable<
  FormattedMarkdownProps & { stackProps?: StackProps }
>(({ numberOfLines, expandMarkdownChildren, stackProps = {}, ...props }) => {
  const markdownProps = useMarkDownProps(props)

  if (numberOfLines) {
    return (
      <YStack {...stackProps}>
        <LimitNumberOfLines
          numberOfLines={numberOfLines}
          textSize={props.textSize || '$5'}
          expandMarkdownChildren={expandMarkdownChildren}
        >
          <YStack gap='$3'>
            <Markdown
              remarkPlugins={REMARK_PLUGINS}
              // rehypePlugins={REHYPE_PLUGINS}
              unwrapDisallowed
              {...markdownProps}
            />
          </YStack>
        </LimitNumberOfLines>
      </YStack>
    )
  }

  return (
    <YStack gap='$3' {...stackProps}>
      <Markdown
        remarkPlugins={REMARK_PLUGINS}
        // rehypePlugins={REHYPE_PLUGINS}
        unwrapDisallowed
        {...markdownProps}
      />
    </YStack>
  )
})

const OrderedList = styled(YStack, {
  tag: 'ol',
  paddingLeft: '$6',
  marginBottom: 0,

  style: {
    listStyleType: 'decimal',
  },
} as const)

const UnorderedList = styled(YStack, {
  tag: 'ul',
  paddingLeft: 0,
  marginBottom: 0,
  marginLeft: 20,

  style: {
    listStyleType: 'disc',
  },
} as const)

const Li = styled(View, {
  tag: 'li',
  className: 'ListItem',

  paddingLeft: 0, // '$2',
  display: 'list-item',
} as const)

const StyledLimitNumberOfLines = styled(YStack, {
  overflowY: 'hidden',
} as const)
